<template>
  <v-navigation-drawer
     style="background-color:#fff"
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu rounded-xl ml-3 mt-3"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-10 pb-3 text-center">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require('@/assets/images/logos/nav_long_logo.png')"
          max-width="210px"
          alt="logo"
          eager
          class="app-logo mb-n13 mt-n13"
        ></v-img>
        <!-- <v-slide-x-transition>
          <h2 class="app-title text--primary">Technicolor</h2>
        </v-slide-x-transition> -->
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items">
      <br />
      <nav-menu-link title="My Space" :to="{ name: 'dashboard' }" :icon="icons.mdiHomeOutline"></nav-menu-link>

     

      <nav-menu-section-title class="mt-n1" title="Analytics Section"></nav-menu-section-title>

      <!-- <nav-menu-link title="Devices Details" :to="{ name: 'deviceDetails' }" :icon="icons.mdiPoll"></nav-menu-link> -->
      
      
      <!-- <nav-menu-link title="Monitoring"  :to="{ name: 'memAnalytics' }" icon="mdi-memory"></nav-menu-link> -->
      <!-- <nav-menu-link title="API Groups"  :to="{ name: 'test-cases-repo' }" icon="mdi-file-multiple"></nav-menu-link>

      <nav-menu-link title="Public tests"  :to="{ name: 'all-n-tests' }" icon="mdi-check-all"> </nav-menu-link>
      
      <nav-menu-link title="All APIs"  :to="{ name: 'all-apis' }" icon="mdi-stack-overflow"></nav-menu-link> -->

      
      <!-- <nav-menu-link title=""  :to="{ name: 'memoryAnalytics' }" icon="mdi-graph"></nav-menu-link> -->
      <!-- <nav-menu-link title="CPU Analysis"  :to="{ name: 'cpuAnalytics' }" icon="mdi-cpu-64-bit"></nav-menu-link>
      <nav-menu-link title="Pressure Analysis"  :to="{ name: 'pressureAnalytics' }" icon="mdi-car-brake-low-pressure"></nav-menu-link> -->
      <!-- <nav-menu-section-title class="mt-n1" title="Extra"></nav-menu-section-title> -->
      <!-- <nav-menu-link title="Log Analysis"  :to="{ name: 'logAnalytics' }" icon="mdi-poll"></nav-menu-link>  -->

      <!-- <nav-menu-link title="Settings"  :to="{ name: 'multirun' }" icon="mdi-cog"></nav-menu-link> -->


      <!-- <nav-menu-link title="Temperature Analysis"  :to="{ name: 'tempAnalytics' }" icon="mdi-sun-thermometer"></nav-menu-link> -->
      <!-- <nav-menu-link title="Process Analysis"  :to="{ name: 'processAnalytics' }" icon="mdi-apps"></nav-menu-link> -->

      <!--<nav-menu-link title="Crash Analytics" :to="{ name: 'simple-table' }" :icon="icons.mdiPoll"></nav-menu-link>-->
      <!-- <nav-menu-section-title class="mt-n1" title="Customer Support (TBD) "></nav-menu-section-title>

      <nav-menu-link title="Crash Report" :icon="icons.mdiPoll"></nav-menu-link>
      <nav-menu-link title="Complaints Overview" icon="mdi-bulletin-board"></nav-menu-link>
      <nav-menu-section-title title="Configuration Section (TBD)" class="mt-n1"></nav-menu-section-title>
      <nav-menu-link title="Topic Details" :icon="icons.mdiAxisArrowInfo"></nav-menu-link>
      <nav-menu-link title="Publish Topic" :icon="icons.mdiSendLock"></nav-menu-link>
      <nav-menu-link title="Subscribe Topic" :icon="icons.mdiPlaylistCheck"></nav-menu-link> -->
      <!-- <nav-menu-group
        title="Pages"
        :icon="icons.mdiFileOutline"
      >
        <nav-menu-link
          title="Login"
          :to="{ name: 'pages-login' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Register"
          :to="{ name: 'pages-register' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Error"
          :to="{ name: 'error-404' }"
          target="_blank"
        ></nav-menu-link>
      </nav-menu-group> -->
      <!-- <nav-menu-section-title title="My account" class="mt-n1"></nav-menu-section-title>
      <nav-menu-link
        title="Account Settings"
        :to="{ name: 'pages-account-settings' }"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link> -->
      <!-- <nav-menu-link
        title="Typography"
        :to="{ name: 'typography' }"
        :icon="icons.mdiAlphaTBoxOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Icons"
        :to="{ name: 'icons' }"
        :icon="icons.mdiEyeOutline"
      ></nav-menu-link> -->
      <!-- <nav-menu-link
        title="Form Layouts"
        :to="{ name: 'form-layouts' }"
        :icon="icons.mdiFormSelect"
      ></nav-menu-link> -->

      <!-- <nav-menu-section-title title="Extra" class="mt-n1"></nav-menu-section-title>
       <nav-menu-link
        title="Help"
        :to="{ name: 'helpPage' }"
        :icon="icons.mdiHelpCircleOutline  "
      ></nav-menu-link>
      <nav-menu-link title="Logout" :to="{ name: 'cards' }" :icon="icons.mdiLogout"></nav-menu-link> -->


    </v-list>
    <!-- <a
      href=""
      target="_blank"
      rel="nofollow"
    >
      <v-img
        :src="require(`@/assets/images/logos/${banner_number}.gif`)"
        alt="upgrade-banner"
        transition="scale-transition"
        class="upgrade-banner mx-auto"
        max-width="240"
      ></v-img>
    </a> -->
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAxisArrowInfo,
  mdiSendLock,
  mdiLogout,
  mdiPoll,
  mdiFileOutline,
  mdiPlaylistCheck,
  mdiAccountCogOutline,
  mdiHelpCircleOutline 
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      banner_number : 0
    }
  },
  created() {
     setInterval(() => {
      if(this.banner_number <1)
      {
      this.banner_number ++;
      }
      else{
        this.banner_number = 0;
      }
     },5000)
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAxisArrowInfo,
        mdiSendLock,
        mdiLogout,
        mdiPoll,
        mdiFileOutline,
        mdiPlaylistCheck,
        mdiAccountCogOutline,
        mdiHelpCircleOutline 
      },
    }
  },
}
</script>

<style lang="scss" >
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}


.v-application--is-ltr .v-list.v-sheet--shaped .v-list-item, .v-application--is-ltr .v-list.v-sheet--shaped .v-list-item::before,
.v-application--is-ltr .v-list.v-sheet--shaped .v-list-item > .v-ripple__container {
  border-radius: 32px !important;
  margin-left: 4px;
}
.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.v-navigation-drawer__content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #5d5d5d;
  background-color: #5d5d5d;
}
.v-navigation-drawer__content::-webkit-scrollbar {
  width: 0px;
}
.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px #424242;
  background-color: #424242;
}
</style>
