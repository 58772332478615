import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    license : 'Platinum',
    ActiveIndex: null,
    AccessToken: null,
    ActiveTab : 0,
    useremail:null,
    user: {
      loggedIn: false,
      data: null
    }
  },
  getters: {
    user(state){
      return state.user
    },
    license(state){
      return state.license
    },
    ActiveIndex(state){
      return state.ActiveIndex
    },
    ActiveTab(state){
      return state.ActiveTab
    },
    useremail(state){
      return state.useremail
    },
    AccessToken(state)
    {
      return state.AccessToken
    }
  },
  mutations: { SET_LOGGED_IN(state, value) {
    state.user.loggedIn = value;
  },
  SET_USER(state, data) {
    state.user.data = data;
  },
    SET_LICENSE(state, value) {
      state.license = value;
    },
    SET_ACTIVEINDEX(state, value) {
      state.ActiveIndex = value;
    },
    SET_ACTIVETAB(state, value) {
      state.ActiveTab = value;
    },
    SET_useremail(state,value){
      state.useremail = value;
    },
    SET_ACCESS_TOKEN(state, value) {
      state.AccessToken = value;
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);

      if (user) {
        commit("SET_USER", user);
      } else {
        commit("SET_USER", null);
      }
    }
  },
  modules: {},
})
