import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from '../auth/authGuard';
import firebase from "firebase/compat/app";
import 'firebase/compat'
import "firebase/compat/auth";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank'

    },
  },

  {
    path: '/dashboard',
    name: 'dashboard',

    component: () => import('@/views/dashboard.vue'),
    meta: {
      requiresAuth: true,
      layout: 'blank',
    },
    // beforeEnter: authGuard,
  },
  {
    path: '/qrcode',
    name: 'qrcode',
    meta: { requiresAuth: true },

    component: () => import('@/views/qrcode.vue'),
    meta: {
      layout: 'blank',
    },
    // beforeEnter: authGuard,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
function maskedPrompt(message) {
  let password = "";
  while (true) {
    let char = prompt(message + password.replace(/./g, "*"));
    if (char === null) return null;
    password += char;
  }
  return password;
}

router.beforeEach((to, from, next) => {
  const loggedIn = !!localStorage.getItem('accessToken');


  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!loggedIn) {
      console.log('Redirecting to login page...');
      next('/login');
    } else {
      console.log('Proceeding to requested route...');
      next();
    }
  } else {
    console.log('No authentication required. Proceeding to requested route...');
    next();
  }
});



export default router
