import { io } from 'socket.io-client'

class SocketioService {
  socket

  constructor() {}

  setupSocketConnection() {
    this.socket = io('http://10.41.76.67:3000')

    this.socket.on('connect', () => {
      console.log('Connected to Socket Server')
    })
  }
}

export default new SocketioService()
