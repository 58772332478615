<template>
    <div id="chartPressure" style="height: 30%" ></div>
    <!-- <v-chart class="mt-3" :option="option" /> -->
</template>

<script>
import 'echarts'
import axios from 'axios'
import moment from 'moment'
import * as echarts from 'echarts'

import VChart, { THEME_KEY } from 'vue-echarts'

export default {
  name: 'HelloWorld',
  components: {
    VChart,
  },
  props: ['newAllData', 'item', 'date'],

  watch: {
    date: function (newVal, oldVal) {
      this.pressure_graphs(newVal)
    },
    newAllData: function (newVal, oldVal) {
      console.log(newVal['response']['timestamp'])
      // watch it
      if(newVal['response']['/proc/pressure/io'])
      {
      this.option.series[0].data.shift()

         this.option.series[0].data.push([newVal['response']['timestamp'],newVal['response']['/proc/pressure/io']['some']['avg10']])
  

      }
      if(newVal['response']['/proc/pressure/cpu'])
      {
      this.option.series[1].data.shift()
         this.option.series[1].data.push([newVal['response']['timestamp'],newVal['response']['/proc/pressure/cpu']['some']['avg10']])

      }
      if(newVal['response']['/proc/pressure/memory'])
      {
      this.option.series[2].data.shift()
         this.option.series[2].data.push([newVal['response']['timestamp'],newVal['response']['/proc/pressure/memory']['some']['avg10']])


     

      }
      // this.option.xAxis[0].data.shift()
      this.myChart.setOption(this.option, { notMerge: false })
    },
  },

  data() {
    return {
      x_data: [],
      myChart: null,
      y_data: [],
      option: {
        height: '150px',
        darkMode: 'auto',
        color: ['#FFB400', '#08A88A', '#FF4C51'],
        gradientColor: ['#FFB400', '#08A88A', '#FF4C51'],

        // Make gradient line here
        visualMap: [
          {
            show: false,
            type: 'continuous',
            seriesIndex: 0,
            min: 0,
            max: 100,
          },
        ],
        legend: {
          data: ['Pressure IO', 'Pressure CPU', 'Pressure MEMORY'],
        },
        dataZoom: {
          type: 'inside',
          zoomOnMouseWheel: true,
          zoomOnMouseMove: true,

          start: 0,
          end: 100,
        },
        toolbox: {
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: {},
            dataZoom: {
              show: true,
              yAxisIndex: 'none',
            },
          },
        },
        title: [
          {
            text: 'Pressure Some avg10',
          },
          
        ],
        tooltip: {
          trigger: 'none',
          axisPointer: {
            type: 'cross',
          },
        },
        xAxis: {
         type: 'time',
        name: 'Timestamp'
        
        },
        yAxis: {
          type: 'value',
        name: 'Value'
        },
        grid: [
          {
            bottom: '60%',
          },
          {
            top: '50%',
          },
        ],
        series: [
          {
            name: 'Pressure IO',
            stack: 'Total',
            symbolSize: 10,
            type: 'line',

            data: [],
            label: {
              show: true,
              position: 'top',
              textStyle: {
                fontSize: 10,
              },
            },
          },
          {
            name: 'Pressure CPU',
            symbolSize: 10,
            type: 'line',
            stack: 'Total',
            markPoint: {
              data: [
                { type: 'max', name: 'Max' },
                { type: 'min', name: 'Min' },
              ],
            },

            data: [],
            label: {
              show: true,
              position: 'top',
              textStyle: {
                fontSize: 10,
              },
            },
          },
          {
            name: 'Pressure MEMORY',
            symbolSize: 10,
            type: 'line',
            data: [],
            label: {
              show: true,
              position: 'top',
              textStyle: {
                fontSize: 10,
              },
            },
          },
        
        ],
      },
    }
  },
  mounted() {
    this.pressure_graphs('', this.item.deviceId)
  },
  methods: {
    async pressure_graphs(date, deviceId) {
      await axios
        .post('http://10.41.76.67:5001/graph-data', {device_id: deviceId  })
        .then(response => {
          response.data.data
            .slice()
            .reverse()
            .forEach(item => {
              console.log(item)
              if(item['/proc/pressure/io'])
              {
              this.option.series[0].data.push([item['timestamp'],item['/proc/pressure/io']['some']['avg10']])
              }

              if(item['/proc/pressure/cpu']){
              this.option.series[1].data.push([item['timestamp'],item['/proc/pressure/cpu']['some']['avg10']])
              }

              if(item['/proc/pressure/memory'])
              {
              this.option.series[2].data.push([item['timestamp'],item['/proc/pressure/memory']['some']['avg10']])
              }
            })


          // this.option.series[0].data = this.y_data

          console.log(this.x_data)
          var chartDom = document.getElementById('chartPressure')
          this.myChart = echarts.init(chartDom)
          this.option && this.myChart.setOption(this.option)
        })
    },
  },
}
</script>

<style scoped>
.chart {
  height: 75vh;
}
</style>
