require('./overrides.scss')

export default {
  theme: {
    themes: {
      light: {
        primary: '#08A88A',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        white:'#fff',
        black:'#000',
        light:'#C6E7E4',
        lightGreen : '#64CF15',
        lightRed : '#F6613C',
        lightBlue: '#C6E7E4',
        lightPurple : '#7E6BEA',
        lightYellow: '#FFB911',
        lightWhite: '#AABEC8'

      },
      dark: {
        primary: '#9155FD',
        accent: '#0d6efd',
        secondary: '#8A8D93',
        success: '#56CA00',
        info: '#16B1FF',
        warning: '#FFB400',
        error: '#FF4C51',
        light:'#F2FAFF'
      }
     
     
    },
  },
}
