import '@/styles/styles.scss'
import Vue from 'vue'
import VueIframe from 'vue-iframes'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import moment from 'moment'
import SocketioService from '@/helperFunctions/socket'
import VueTyperPlugin from 'vue-typer'
import {Datetime} from 'vue-datetime';
import VueSweetalert2 from 'vue-sweetalert2'; 
import 'sweetalert2/dist/sweetalert2.min.css';







Vue.use(moment);
Vue.use(VueIframe);
Vue.use(VueTyperPlugin);
Vue.use(VueSweetalert2);

Vue.component('datetime', Datetime);

Vue.config.productionTip = false
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
